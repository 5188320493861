.testimonials-page-section-one {
  display: flex;
  justify-content: column;
  align-items: column;
  padding: 3em 3em;
  gap: 5em;
  align-items: center;
  margin-left: 10em;
  margin-right: 10em;
}

.testimonials-content {
  display: flex;
  flex-direction: column;
}

.testimonials-content img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.testimonials-content h1 {
  text-align: left;
}

@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .testimonials-page-section-one {
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding: 2em; /* Adjust padding */
    margin-left: 5em; /* Reduce margins for smaller screens */
    margin-right: 5em;
    gap: 2em; /* Reduce gap */
  }
}
@media only screen and (max-width: 600px) {
  .testimonials-page-section-one {
    flex-direction: column; /* Ensure items are stacked vertically */
    padding: 1em; /* Reduce padding further for smaller screens */
    margin-left: 2em; /* Adjust margins for very small screens */
    margin-right: 2em;
    gap: 1em; /* Reduce gap for very small screens */
  }
}
