.area-photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 30px; /* Adjust the gap between images */
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Adjust based on your preference */
  margin: auto;
}
.area-photo-intro {
  text-align: center;
  font-size: 1.25rem;
}

.area-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.area-photos img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  border: 4px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
@media (max-width: 900px) {
  .area-photos {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }

  .area-photo-intro {
    font-size: 0.9rem; /* Optionally adjust the font size if needed */
    padding: 0 10px; /* Add some padding to keep text from touching the screen edges */
  }
}
