@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
.app {
  margin: 0px 70px;
  min-height: 120vh;
  /* box-shadow: 0 0 35px rgba(186, 158, 172, 1); */
}

* {
  font-family: "Roboto", sans-serif;
}

body {
  background: linear-gradient(to bottom, #fdbda1, #fef2d8, white, white);
}

.main {
  box-shadow: 0 0 35px rgba(186, 158, 172, 1);
  background-color: white;
  height: 100vh;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footer-copyright {
  color: black;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.75em;
}

h1,
h2,
h3 {
  color: #61455d;
}

h1 {
  font-size: 1.75em;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h2 {
  font-size: 1.5em;
  margin-bottom: 1rem;
}
h3 {
  font-size: 1.35em;
  margin-bottom: 0.2rem;
}
p {
  font-size: 1.25em;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  h2,
  h3 {
    font-size: 1.2em;
  }
  h1 {
    font-size: 1.5em;
  }
  p {
    font-size: 1em;
  }
  .app {
    margin: 0;
  }
  .page-padding {
    padding: 0.25rem;
  }

  footer {
    display: none;
  }

  .app {
    margin: 0;
  }
  .page-padding {
    padding: 0.25rem;
  }

  footer {
    display: none;
  }
}
