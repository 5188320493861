.page-padding {
  padding: 2rem;
}
.location-page-section-one,
.location-page-section-two-article-three {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem;
}
.location-page-section-one article p {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 1rem;
  padding: 0 10rem;
  text-align: center;

  @media (max-width: 1450px) {
    padding: 0 4rem;
  }
  @media (max-width: 1100px) {
    padding: 0 1rem;
  }
  @media (max-width: 900px) {
    padding: 0;
  }
}

.location-page-section-one article img {
  width: 50%;
  height: auto;
  align-self: center;
}
.location-page-section-one img {
  flex: 2;
  max-width: 40vw;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  border: 3px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
.location-page-section-one article {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.google-map {
  width: 100%;
  height: 400px;
  max-width: 400px;
  margin: auto;
  margin: bottom 200rem;
}

@media (max-width: 900px) {
  .location-page-section-one,
  .location-page-section-two-article-three {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  .location-page-section-one img {
    height: 300px;
    margin-bottom: 1.5rem;
  }
  .google-map {
    height: 300px;
    margin-bottom: 1.5rem;
    overflow-y: scroll;
  }
}
