.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.link-to-location-page {
  color: black;
  text-decoration: underline;
}

.contact-section-one {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 3rem;
}
.contact-section-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-section-text h1 {
  text-align: center;
}
.contact-section-one img {
  width: 30%;
  border-radius: 0.5rem;
  border: 5px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}

@media screen and (max-width: 1400px) {
  .contact-section-one img {
    width: 40%;
  }
}

.email-title {
  text-align: center;
}

.large-form-container,
.small-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(226, 192, 226);
  border: 1px solid black;
  padding: 2em;
  margin: 2rem auto;
  border-radius: 0.5rem;
  max-width: 70vw;
}

.large-form-container button {
  padding: 0.5rem 1rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.large-form-container button:hover,
.small-form-container button:hover {
  background-color: #6f8bb8;
}

.small-form-container {
  display: none;
}

form {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-large {
  display: flex;
  flex-direction: column;
}

.form-large-section-one {
  display: flex;
}

.form-labels,
.form-inputs {
  display: flex;
  flex-direction: column;
}

label,
input,
select {
  flex: 1;
  padding: 0.25rem 1rem;
  border-radius: 8px;
  border: none;
  resize: none;
}

label,
input,
select {
  margin-bottom: 10px;
}

.form-large-section-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-large-section-two textarea {
  width: 100%;
  height: 10rem;
  border: none;
  border-radius: 10px;
  margin-bottom: 1rem;
  resize: none;
}

.form-large-section-two button {
  padding: 0.5rem 1rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.form-large-section-two button:hover {
  background-color: #6f8bb8;
  font-size: 1.1rem;
}

.email-title p {
  font-weight: bold;
  text-decoration: underline;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
}

.form-small {
  display: none;
}

@media only screen and (max-width: 900px) {
  form {
    gap: 0;
  }
  .form-small {
    display: flex;
  }

  .large-form-container {
    display: none;
  }

  .small-form-container {
    display: flex;
  }

  .form-small textarea {
    width: 100%;
    height: 10rem;
    border: none;
    border-radius: 10px;
    margin-bottom: 1rem;
    resize: none;
  }

  .form-small input,
  .form-small select {
    margin-top: 0;
    margin-bottom: 2rem;
    width: 80%;
    padding: 0.25rem;
    text-align: center;
  }

  .form-small label {
    margin-bottom: 0;
    padding: 0;
  }

  .small-form-container button {
    padding: 0.5rem 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .contact-section-one {
    flex-direction: column;
    align-items: center;

    padding: 0.5rem;
    gap: 1rem;
  }
  .contact-section-one img {
    width: 50%;
    border-radius: 0.5rem;
  }

  .form-large {
    display: none;
  }
}
@media (max-width: 768px) {
  .form-section-one-small-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
@media (max-width: 400px) {
  .contact-section-text h1 {
    font-size: 1.3em;
  }
}

.success {
  color: green;
  font-weight: bold;
  text-align: center;
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
}
