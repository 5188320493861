.motel-photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 3em; /* Adjust the gap between images */
  justify-content: center;
  max-width: 80%; /* Adjust based on your preference */
  margin: auto;
}
.motel-photos-intro {
  text-align: center;
  font-size: 1.25rem;
}

.motel-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.motel-photos img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 8px solid white;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
@media (max-width: 900px) {
  .motel-photos {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .motel-photos-intro {
    font-size: 0.9rem; /* Optionally adjust the font size if needed */
    padding: 0 10px; /* Add some padding to keep text from touching the screen edges */
  }
}
@media screen and (max-width: 300px) {
  .motel-photos {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }
}
