.website-links {
  color: black;
  text-decoration: underline;
  margin-bottom: 10px;
}
.flex-wrapper {
  overflow-y: hidden;
}

h3 {
  margin-top: 20px;
  margin-bottom: 0px;
}
section a {
  display: flex;
  flex-wrap: wrap;
}

.local-links-container section {
  border-bottom: 3px solid #333; /* Dark grey line */
  margin: 1em;
  padding: 1em;
}
.local-link-intro {
  text-align: center;
}
.img-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px; /* Adjust the gap between images */
  justify-content: center;

  max-width: 100%; /* Adjust based on your preference */
  margin: auto;
}
.img-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
}
.img-section img:hover {
  transform: scale(1.05); /* Scales the image up to 105% of its original size */
  transition: transform 0.3s ease; /* Smooth transition effect */

  transition: filter 0.3s ease; /* Smooth transition effect */
}
@media (max-width: 900px) {
  .img-section {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 300px) {
  section a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
