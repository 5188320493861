/* General header styling */
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #708bb8;
}

.hero-image {
  width: 100%; /* Adjusted to ensure it's fully responsive */
  display: block;
  margin-bottom: 0.5em;
}

.hero {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  list-style: none;
  /* background-color: #8b6185; */
  padding: 0.5rem 3rem;
  border-radius: 15px;
  font-size: 1em;
  min-width: 80vw;
  @media (max-width: 1100px) {
    font-size: 0.75em;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 1em 0em;
    margin: 0;
  }
}

a {
  text-decoration: none;
  color: white;
}

.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .hamburger {
    display: block;
  }
}
