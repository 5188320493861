.home-top-text {
  font-size: 0.73em;
  text-align: center;
  box-shadow: none;
  padding: 0.2rem;
}
.home-page-section-one {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 3rem;
}
.home-page-section-one img {
  width: 80%;
  height: auto;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  border: 3px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}

.home-page-section-one-article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.75rem;
}

.home-page-section-two {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}
.home-page-section-two img {
  width: 100%;
  height: auto;

  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
.home-page-section-two img:hover {
  transform: scale(1.25);
  transition: ease-in-out 0.5s;
}
.home-page-section-two-paragraph {
  font-size: 1em;
  font-weight: bold;
}
.home-page-section-two-article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.home-page-section-three {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.home-page-section-three img {
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  border: 3px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
  max-width: 100%;
}
@media (max-width: 900px) {
  .home-page-section-one,
  .home-page-section-two,
  .home-page-section-three {
    flex-direction: column; /* Stack elements vertically */
    padding: 1rem; /* Adjust padding for a more compact layout */
    gap: 1rem; /* Reduce gap between elements */
  }

  .home-page-section-one img,
  .home-page-section-three img {
    max-width: 100%; /* Ensure images are not wider than the screen */
    height: auto; /* Maintain aspect ratio */
  }
  .home-page-section-one img {
    width: 50%;
  }

  .home-page-section-one-article,
  .home-page-section-two-article {
    padding: 0.5rem; /* Adjust padding for article elements */
    text-align: center; /* Center text content */
  }

  .home-page-section-two-paragraph {
    font-size: 0.9em; /* Adjust font size for better readability */
  }
}
