.sunset-photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 30px;
  justify-content: center;
  max-width: 100%;
  margin: auto;
}

.sunset-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sunset-photo-intro {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.sunset-photos img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  border: 4px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
@media (max-width: 900px) {
  .sunset-photos {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }
}
