.room-page-section-one,
.room-page-section-two,
.room-page-section-three {
  display: flex;
  justify-content: space-between;
  padding: 3em 2em;
  gap: 10em;
  align-items: center;
}
.room-container article {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.room-page-section-two ul li:last-child {
  margin-bottom: 2rem;
}

.page-padding h1 {
  text-align: center;
}

.room-page-section-one p,
.room-page-section-two p,
.room-page-section-three p {
  font-size: 1.2em;
}
.room-page-section-one h2,
.room-page-section-two h2,
.room-page-section-three h2,
.room-page-section-one h3,
.room-page-section-two h3,
.room-page-section-three h3 {
  font-size: 1.5em;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.room-page-section-one,
.room-page-section-two,
.room-page-section-three {
  text-align: center;
}
.rate-list h2 {
  margin-bottom: 1em;
}
.room-page-section-two li {
  font-size: 1.2em;
}

.room-page-section-four {
  display: flex;
  flex-direction: column;
  gap: 0em;
  justify-content: center;
  align-items: center;
}
.room-page-section-four p {
  font-size: 2em;
  color: #8b6185;
  margin-bottom: 1.5em;
}
.page-padding li {
  list-style: none;
  margin-bottom: 0.35em;
}

.li-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.room-page-section-four h1 {
  font-size: 1.75em;
  margin-bottom: 0;
}
.room-page-section-four li {
  font-size: 1.2em;
  color: #8b6185;
}

.room-container ul {
  text-align: center;
  padding: 0;
  margin: 0;
}
.room-images {
  width: 100%;
  max-width: 50%;
  height: auto;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  border: 4px solid #f0f0f0;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.35);
}
/* .rate-list h2 {
  margin-bottom: 1em;
} */
@media (max-width: 1500px) {
  .room-page-section-one,
  .room-page-section-two,
  .room-page-section-three,
  .room-page-section-four {
    flex-direction: column;
    padding: 1em; /* Reduce padding */
    gap: 0em; /* Adjust gap */
    align-items: center; /* Ensure alignment is centered */
    padding: auto;
  }
  .room-page-section-one h2,
  .room-page-section-two h2,
  .room-page-section-three h2,
  .room-page-section-one h3,
  .room-page-section-two h3,
  .room-page-section-three h3 {
    font-size: 1.2em;
  }

  .room-page-section-four p,
  .room-page-section-four li {
    font-size: 1.2em;
  }

  .li-flex {
    flex-direction: column; /* Stack elements vertically in the list items */
    gap: 0em; /* Add some space between the title and price */
  }
  .room-images {
    max-width: 50%; /* Allow the image to scale down further on smaller screens */
  }

  .rate-list * {
    margin: 0.2em; /* Adjust the margin to center the text */
    padding: 0;
  }
}

@media (max-width: 600px) {
  .room-images {
    width: 100%; /* Ensure the image scales with the container */
    max-width: none; /* Override any max-width set for larger screens */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image if needed */
    padding: 5px; /* Optional: add some padding to not touch the screen edges */
  }
  .room-page-section-four p,
  .room-page-section-four li {
    font-size: 0.7em;
    text-align: center; /* Center the text */
  }
  .rate-list * {
    margin: 0;
    padding: 0;
  }

  .room-page-section-one h2,
  .room-page-section-two h2,
  .room-page-section-three h2,
  .room-page-section-one h3,
  .room-page-section-two h3,
  .room-page-section-three h3 {
    font-size: 0.75em;
    align-items: center;
  }
  .room-page-section-one p,
  .room-page-section-two p,
  .room-page-section-three p {
    font-size: 0.75em;
  }
  .room-page-section-two li {
    font-size: 0.75em;
  }
}

.tax-p {
  margin-bottom: 2rem;
}
