.footer-nav-links {
  display: flex;
  background-color: #6f8bb8;
  text-decoration: none;
  list-style: none;
  gap: 0.5rem;
  font-size: 0.7em;
  justify-content: center;
  padding: 0.85rem;
}
.footer-nav-links p {
  color: white;
  margin: 0;
  line-height: 0.75rem;
  font-size: 0.7em;
}
@media (max-width: 768px) {
  .footer-nav-links {
    flex-direction: column; /* Stack links vertically */
    gap: 0.25rem; /* Reduce gap for compact layout */
    padding: 0.85rem 0.5rem; /* Adjust padding for smaller screens */
  }

  .footer-nav-links p {
    display: none; /* Hide separators in vertical layout */
  }

  .footer-nav-links li,
  .footer-nav-links a {
    font-size: 0.85em; /* Slightly increase font size for better readability */
    text-align: center; /* Center-align items for a cleaner look */
  }
}
